import { colors } from '@bitcoin-portal/neko-web-components/dist/themes/colors';
import { State } from './store';

export const CHART_PERIOD: { [period: string]: Intl.DateTimeFormatOptions } = {
  DAILY: {
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  },
  WEEKLY: {
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  },
  MONTHLY: {
    month: 'short',
    day: '2-digit',
    hour12: true,
  },
  YEARLY: {},
};

export const ASSET_NAMES: { [ticker: string]: string | undefined } = {
  BCH: 'Bitcoin Cash',
  BTC: 'Bitcoin',
  ETH: 'Ethereum',
  AVAX: 'Avalanche',
};

export const TIMERS = {
  PORTFOLIO_INIT_DELAY: 1.5 * 1000,
  PORTFOLIO_UPDATE: 10 * 60 * 1000,
  EXCHANGE_RATE_UPDATE: 3 * 1000,
  AUTHENTICATION_CHECK: 20 * 1000,
  WEB_AUTHENTICATION_CHECK: 3 * 1000,
  COIN_INFO_UPDATE: 10 * 1000,
  TOAST_TIMEOUT: 5000,
};

export const COINS = ['BCH', 'BTC', 'ETH', 'AVAX'];

export const ASSETS = [
  {
    blockchain: 'BCH',
    ticker: 'BCH',
    name: 'Bitcoin Cash',
    balance: 0,
    id: 'BCH',
    decimals: 8,
  },
  {
    blockchain: 'BTC',
    ticker: 'BTC',
    name: 'Bitcoin',
    balance: 0,
    id: 'BTC',
    decimals: 8,
  },
  {
    blockchain: 'ETH',
    ticker: 'ETH',
    name: 'Ethereum',
    balance: 0,
    id: 'ETH',
    decimals: 10,
  },
  {
    blockchain: 'AVAX',
    ticker: 'AVAX',
    name: 'Avalanche',
    balance: 0,
    id: 'AVAX',
    decimals: 10,
  },
  {
    blockchain: 'ETH',
    ticker: 'VERSE',
    name: 'Verse',
    balance: 0,
    id: '0x249ca82617ec3dfb2589c4c17ab7ec9765350a18',
    decimals: 18,
  },
];

export const MARKET_CARDS = ['BCH', 'BTC', 'ETH', 'VERSE'];

type AssType = { [id: string]: { ticker: string; color: string } };

// this is temporary. to be removed when these colors come from server
export const ASSET_MAP: AssType = {
  '0x6B3595068778DD592e39A122f4f5a5cF09C90fE2': {
    ticker: 'SUSHI',
    color: '#FA52A0',
  },
  dd21be4532d93661e8ffe16db6535af0fb8ee1344d1fef81a193e2b4cfa9fbc9: {
    ticker: 'flexUSD',
    color: '#313640',
  },
  BTC: { ticker: 'BTC', color: colors.yellow },
  '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984': {
    ticker: 'UNI',
    color: '#FF007A',
  },
  '0xc00e94Cb662C3520282E6f5717214004A7f26888': {
    ticker: 'COMP',
    color: '#00D395',
  },
  '3f83fa9f168f01d68933ef5fdb77143b2376ba7bf3a78175258861982d90d500': {
    ticker: 'GOC',
    color: '#313640',
  },
  '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599': {
    ticker: 'WBTC',
    color: '#F09242',
  },
  '0xdAC17F958D2ee523a2206206994597C13D831ec7': {
    ticker: 'USDT',
    color: '#2FB98E',
  },
  ETH: { ticker: 'ETH', color: colors.violet },
  '9fc89d6b7d5be2eac0b3787c5b8236bca5de641b5bafafc8f450727b63615c11': {
    ticker: 'USDT',
    color: '#313640',
  },
  '4de69e374a8ed21cbddd47f2338cc0f479dc58daa2bbe11cd604ca488eca0ddf': {
    ticker: 'SPICE',
    color: '#313640',
  },
  fb1813fd1a53c1bed61f15c0479cc5315501e6da6a4d06da9d8122c1a4fabb6c: {
    ticker: 'FLEX',
    color: '#313640',
  },
  BCH: { ticker: 'BCH', color: colors.primaryFallback },
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48': {
    ticker: 'USDC',
    color: '#2775CA',
  },
  '0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e': {
    ticker: 'YFI',
    color: '##0064CE',
  },
  AVAX: { ticker: 'AVAX', color: colors.red },
};

export const NOTIFICATION_FREQUENCIES = [5, 10, 15, 30, 60];

export const MARKETS_PAGE_SIZE = 20;

export const PRICE_NOTIF_LIMIT = 9;

export const NOTIF_DISABLED_TOKENS = [
  // USDH
  'c4b0d62156b3fa5c8f3436079b5394f7edc1bef5dc1cd2f9d0c4d46f82cca479',
  // GOC
  '3f83fa9f168f01d68933ef5fdb77143b2376ba7bf3a78175258861982d90d500',
  // FLEX
  'fb1813fd1a53c1bed61f15c0479cc5315501e6da6a4d06da9d8122c1a4fabb6c',
  // FlexUSD
  'dd21be4532d93661e8ffe16db6535af0fb8ee1344d1fef81a193e2b4cfa9fbc9',
  // USDT - SLP
  '9fc89d6b7d5be2eac0b3787c5b8236bca5de641b5bafafc8f450727b63615c11',
  // SPICE
  '4de69e374a8ed21cbddd47f2338cc0f479dc58daa2bbe11cd604ca488eca0ddf',
];

export const { STYTCH_PUBLIC_TOKEN, LOGIN_REDIRECT_URL } = process.env;

export const activeEnv =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

export const CACHED_STATE_KEYS: Array<keyof State> = ['selectedFiat'];

export const CUSTOM_RATE_DECIMALS: { [crypto: string]: number | undefined } = {
  VERSE: 5,
};
