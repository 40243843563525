import { INITIAL_STATE, State } from '../store';
import { CACHED_STATE_KEYS } from '../constants';

export type LogoutAction = { type: 'LOGOUT' };

export const logoutReducer = (state: State): State => {
  const cachedState: State = CACHED_STATE_KEYS.reduce(
    (p, c) => {
      return {
        ...p,
        [c]: state[c],
      };
    },
    { ...INITIAL_STATE },
  );
  return cachedState;
};
