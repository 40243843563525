import { createContainer } from 'react-tracked';
import { useEffect, Dispatch, useReducer, useCallback } from 'react';
import { InboxMessage, TAssetInfo } from '@bitcoin-portal/neko-web-sdk';
import { reducer, Action } from './reducer';
import { WalletsPayload } from './reducers/wallets';
import { GeoLocationPayload } from './reducers/geolocation';
import { UserDetailsPayload } from './reducers/userDetails';
import {
  GeneralNotificationPayload,
  PriceNotificationPayload,
  EmailSubscriptionPayload,
} from './reducers/userNotifications';
import { LocalePayload } from './reducers/locale';
import { ChartPeriodPayload } from './reducers/chartPeriod';
import { ThemePayload } from './reducers/theme';
import { CoinInfoPayload } from './reducers/coinInfo';
import { PortfolioPayload } from './reducers/portfolio';
import { MarketChartsPayload } from './reducers/markets';
import { SelectedWalletPayload } from './reducers/selectedWallet';
import { FiatCurrencyPayload } from './reducers/fiatCurrency';
import { AssetType, ExchangeRatesType } from './types';
import { RecentTxsPayload } from './reducers/recentTxs';
import { LiveChartPayload } from './reducers/chartPoint';
import {
  LinkedDevicesPayload,
  WalletsDisabledPayload,
} from './reducers/device';
import { TMarketsFavorites } from './reducers/marketsFavorites';

import { isFeatureFlagEnabled } from '../helpers/featureFlags';

export type State = {
  selectedWallet?: SelectedWalletPayload;
  selectedFiat: FiatCurrencyPayload;
  theme: ThemePayload;
  wallets: WalletsPayload;
  exchangeRates: ExchangeRatesType;
  liveChart: LiveChartPayload;
  chartPeriod: ChartPeriodPayload;
  locale: LocalePayload;
  userDetails?: UserDetailsPayload;
  geolocation?: GeoLocationPayload;
  generalNotifications?: GeneralNotificationPayload[];
  priceNotifications?: PriceNotificationPayload[];
  subscribedToEmails?: EmailSubscriptionPayload;
  marketCharts?: MarketChartsPayload;
  coinInfo?: CoinInfoPayload;
  portfolio: PortfolioPayload;
  assets: AssetType[];
  supportedAssets: TAssetInfo[];
  cryptos?: string[];
  recentTxs?: RecentTxsPayload;
  inboxMessages: InboxMessage[];
  marketsFavorites?: TMarketsFavorites;
  linkedDevices?: LinkedDevicesPayload;
  walletsDisabled?: WalletsDisabledPayload;
};

export const INITIAL_STATE: State = {
  selectedWallet: undefined,
  selectedFiat: 'USD',
  theme: 'light',
  wallets: [],
  exchangeRates: {},
  liveChart: [],
  chartPeriod: 'DAILY',
  locale: 'en',
  userDetails: undefined,
  geolocation: undefined,
  generalNotifications: [],
  priceNotifications: [],
  subscribedToEmails: { news: false, priceAlerts: false, wallet: true },
  marketCharts: undefined,
  coinInfo: undefined,
  portfolio: [],
  assets: [],
  cryptos: [
    'BTC',
    'BCH',
    'ETH',
    'AVAX',
    'SPICE',
    'USDT',
    'USDH',
    'FLEXUSD',
    'UNI',
  ],
  supportedAssets: [],
  inboxMessages: [],
  ...(isFeatureFlagEnabled('marketsFavorites') && { marketsFavorites: [] }),
  linkedDevices: [],
  walletsDisabled: {},
};

const storageKey = 'bitcoincom-wallet-web';

const init = () => {
  try {
    const item = window.localStorage.getItem(storageKey);
    if (item == null) return INITIAL_STATE;
    const preloadedState = { ...INITIAL_STATE, ...JSON.parse(item) };
    return preloadedState || INITIAL_STATE;
  } catch (e) {
    return INITIAL_STATE;
  }
};

const useValue = (): [State, Dispatch<Action>] => {
  const [state, dispatch] = useReducer(reducer, null, init);

  /* useEffect(() => {
    console.log('state', state);
  }, [state]); */

  const dispatchWithLogging = useCallback(action => {
    // console.log('action', action);
    dispatch(action);
  }, []);

  useEffect(() => {
    window.localStorage.setItem(storageKey, JSON.stringify(state));
  }, [state]);

  return [state, dispatchWithLogging];
};

export const {
  Provider,
  useTrackedState,
  useUpdate: useDispatch,
} = createContainer(useValue);
