import { TFeatureFlag, TFeatureFlagId } from './types';
import development from '../../featureFlags/development.json';
import staging from '../../featureFlags/staging.json';
import production from '../../featureFlags/production.json';

const getAllFeatureFlags = (
  environment: string | undefined,
): TFeatureFlag[] => {
  switch (environment) {
    case 'staging':
      return staging;
    case 'production':
      return production;
    default:
      return development;
  }
};

export const featureFlags = getAllFeatureFlags(process.env.GATSBY_ACTIVE_ENV);

export const getFeatureFlag = (id: TFeatureFlagId): TFeatureFlag | undefined =>
  featureFlags.find(item => item.id === id);

export const isFeatureFlagEnabled = (id: TFeatureFlagId): boolean => {
  return featureFlags.some(item => item.id === id && item.enabled);
};

export const useFeatureFlag = (
  id: TFeatureFlagId,
): {
  runIfEnabled: (callback: () => void) => void;
  isEnabled: boolean;
  featureFlag: TFeatureFlag | undefined;
} => {
  return {
    runIfEnabled: (callback: () => void) => {
      return isFeatureFlagEnabled(id) ? callback() : null;
    },
    isEnabled: isFeatureFlagEnabled(id),
    featureFlag: getFeatureFlag(id),
  };
};

export default featureFlags;
