import { TAssetInfo } from '@bitcoin-portal/neko-web-sdk';
import { COINS } from '../constants';
import { State } from '../store';

export type SupportedAssetsAction = {
  type: 'SET_SUPPORTED_ASSETS';
  payload: TAssetInfo[];
};

export const supportedAssetsReducer = (
  state: State,
  payload: TAssetInfo[],
): State => {
  const assets = payload.sort((a, b) => {
    if (a.ticker.toUpperCase() > b.ticker.toUpperCase()) return 1;
    if (a.ticker.toUpperCase() < b.ticker.toUpperCase()) return -1;
    return 0;
  });

  const priorityAssets = assets.filter(coin => COINS.includes(coin.ticker));

  // TODO: workaround to add avax until we can completely remove supportedAssets logic
  return {
    ...state,
    supportedAssets: priorityAssets,
  };
};
