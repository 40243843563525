import { State } from '../store';

export type ThemePayload = 'dark' | 'light';

export type ToggleThemeAction = { type: 'TOGGLE_THEME' };
export type ThemeAction = { type: 'SET_THEME'; payload: ThemePayload };

export const toggleThemeReducer = (state: State): State => ({
  ...state,
  theme: state.theme === 'light' ? 'dark' : 'light',
});

export const setThemeReducer = (
  state: State,
  payload: ThemePayload,
): State => ({ ...state, theme: payload });
