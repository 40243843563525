import { State } from '../store';

export type TBuyProvider = {
  assets: string[];
  provider: string;
};

export type GeoLocationPayload = {
  countryCode: string;
  ip: string;
  isBitcoinBuyAllowed: boolean;
  provider: TBuyProvider;
};

export type GeoLocationAction = {
  type: 'SET_GEO_LOCATION';
  payload?: GeoLocationPayload;
};

export const geoLocationReducer = (
  state: State,
  payload?: GeoLocationPayload,
): State => ({ ...state, geolocation: payload });
