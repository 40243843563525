import { State } from '../store';

export type LocalePayload = string;

export type LocaleAction = { type: 'SET_LOCALE'; payload: string };

export const localeReducer = (state: State, payload: LocalePayload): State => ({
  ...state,
  locale: payload,
});
