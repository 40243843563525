exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-auth-mobile-tsx": () => import("./../../../src/pages/auth/mobile.tsx" /* webpackChunkName: "component---src-pages-auth-mobile-tsx" */),
  "component---src-pages-auth-web-tsx": () => import("./../../../src/pages/auth/web.tsx" /* webpackChunkName: "component---src-pages-auth-web-tsx" */),
  "component---src-pages-create-tsx": () => import("./../../../src/pages/create.tsx" /* webpackChunkName: "component---src-pages-create-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-inbox-tsx": () => import("./../../../src/pages/inbox.tsx" /* webpackChunkName: "component---src-pages-inbox-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-markets-tsx": () => import("./../../../src/pages/markets.tsx" /* webpackChunkName: "component---src-pages-markets-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-settings-currency-tsx": () => import("./../../../src/pages/settings/currency.tsx" /* webpackChunkName: "component---src-pages-settings-currency-tsx" */),
  "component---src-pages-settings-devicemanagement-tsx": () => import("./../../../src/pages/settings/devicemanagement.tsx" /* webpackChunkName: "component---src-pages-settings-devicemanagement-tsx" */),
  "component---src-pages-settings-language-tsx": () => import("./../../../src/pages/settings/language.tsx" /* webpackChunkName: "component---src-pages-settings-language-tsx" */),
  "component---src-pages-settings-notifications-tsx": () => import("./../../../src/pages/settings/notifications.tsx" /* webpackChunkName: "component---src-pages-settings-notifications-tsx" */),
  "component---src-pages-settings-system-info-tsx": () => import("./../../../src/pages/settings/system-info.tsx" /* webpackChunkName: "component---src-pages-settings-system-info-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-pages-swap-tsx": () => import("./../../../src/pages/swap.tsx" /* webpackChunkName: "component---src-pages-swap-tsx" */),
  "component---src-pages-wallets-tsx": () => import("./../../../src/pages/wallets.tsx" /* webpackChunkName: "component---src-pages-wallets-tsx" */),
  "component---src-pages-welcome-tsx": () => import("./../../../src/pages/welcome.tsx" /* webpackChunkName: "component---src-pages-welcome-tsx" */)
}

