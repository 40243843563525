import React, { FunctionComponent } from 'react';
import { IntlProvider } from 'react-intl';
import merge from 'lodash/merge';

import global from './global/en.json';
import flattenMessages from '../helpers/flattenMessages';
import dashboard from './dashboard';
import download from './download';
import login from './login';
import buysell from './buysell';
import wallets from './wallets';
import swap from './swap';
import settings from './settings';
import error404 from './404';
import welcome from './welcome';
import inbox from './inbox';
import markets from './markets';
import create from './create';
import news from './news';
import mobile from './auth/mobile';
import web from './auth/web';

const DEFAULT_LOCALE = 'en';
export const appLocales: {
  [locale: string]: { path: string; locale: string; default?: boolean };
} = {
  en: {
    path: 'en',
    locale: 'English',
    default: true,
  },
  /*
  zh: {
    path: 'zh',
    locale: '中文',
  },
  ru: {
    path: 'ru',
    locale: 'Русский',
  },
  es: {
    path: 'es',
    locale: 'Español',
  },
    ja: {
      path: 'ja',
      locale: '日本語',
    },
  ko: {
    path: 'ko',
    locale: '한국어',
  },

  fr: {
    path: 'fr',
    locale: 'Français',
  },
  */
};

const messagesMap: { [locale: string]: Record<string, unknown> } = {
  '404': error404,
  home: login,
  dashboard,
  download,
  login,
  create,
  wallets,
  swap,
  buysell,
  settings,
  language: settings,
  currency: settings,
  notifications: settings,
  linkwallet: settings,
  'system-info': settings,
  devicemanagement: settings,
  welcome,
  inbox,
  markets,
  news,
  mobile,
  web,
};

interface Props {
  localeDir: string;
  locale: string;
  netlify?: Record<string, unknown>;
}

// THIS WHOLE FUNCTION SHOULD BE RE-WRITTEN
// LOCALIZATION IN THIS PROJECT DOESN'T HAPPEN AT PAGE LEVEL
// SO MOST OF THIS IS REDUNDENT
const Intl: FunctionComponent<Props> = props => {
  const { localeDir, children, locale, netlify } = props;

  const splitLocaleDir = localeDir.split('/').reduce((prev: string[], curr) => {
    if (curr === '') return prev;
    return [...prev, curr];
  }, []);

  // console.log(splitLocaleDir);
  let pageName = '404';

  Object.keys(messagesMap).forEach(value => {
    if (splitLocaleDir.includes(value)) pageName = value;
  });

  pageName = splitLocaleDir[splitLocaleDir.length - 1];

  if (splitLocaleDir.length === 0) pageName = 'home';
  if (
    splitLocaleDir.length === 1 &&
    Object.keys(appLocales).includes(splitLocaleDir[0])
  )
    pageName = 'home';
  /* locale === DEFAULT_LOCALE && splitLocaleDir.length === 0
      ? 'home'
      : splitLocaleDir[0]; */

  // console.log(pageName);

  const locales = messagesMap[pageName] || messagesMap['404'];
  // console.log(locales[locale]);
  const messages = netlify
    ? merge({}, locales[DEFAULT_LOCALE], {
        ...flattenMessages(global, 'global'),
        ...flattenMessages(netlify, pageName),
      })
    : merge({}, locales[DEFAULT_LOCALE], locales[locale]);

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={DEFAULT_LOCALE}
      messages={messages}
    >
      {children}
    </IntlProvider>
  );
};

export default Intl;
