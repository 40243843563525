import { State } from '../store';

export type CoinInfo = {
  '24hVolumeUsd': number;
  algorithm: string;
  availableSupply: number;
  blockNumber: number;
  blockTime: number;
  // eslint-disable-next-line camelcase
  coin_dominance: number;
  imageUrl: string;
  marketCapUsd: number;
  maxSupply: number;
  name: string;
  percentChange1h: number;
  percentChange7d: number;
  percentChange24h: number;
  priceUsd: number;
  proofType: string;
  rank: number;
  symbol: string;
  timestamp: number;
  uid: string;
};

export type CoinInfoPayload = {
  [coin: string]: CoinInfo;
};

export type CoinInfoAction = {
  type: 'SET_COIN_INFO';
  payload: CoinInfoPayload;
};

export const coinInfoReducer = (
  state: State,
  payload: CoinInfoPayload,
): State => ({ ...state, coinInfo: payload });
