import { State } from '../store';

export type UserDetailsPayload = {
  name: string;
  email: string;
  hasWallets: boolean;
  userId: string;
};

export type UserDetailsAction = {
  type: 'SET_USER_DETAILS';
  payload?: UserDetailsPayload;
};

export const userDetailsReducer = (
  state: State,
  payload?: UserDetailsPayload,
): State => ({ ...state, userDetails: payload });
