import { State } from '../store';

export type FiatCurrencyPayload = string;

export type FiatCurrencyAction = { type: 'SET_FIAT_CURRENCY'; payload: string };

export const fiatCurrencyReducer = (
  state: State,
  payload: FiatCurrencyPayload,
): State => ({ ...state, selectedFiat: payload });
