export const getStytchRedirectURL = (env: string): string => {
  switch (env) {
    case 'production':
      return 'https://app.bitcoin.com/auth/web/';
    case 'staging':
      return 'https://web-client.stage.cloud.bitcoin.com/auth/web/';
    default:
      return 'https://web-client.dev.cloud.bitcoin.com/auth/web/';
    // return 'http://localhost:3000/auth/web/';
  }
};

export const getStytchPublicToken = (env: string): string => {
  switch (env) {
    case 'production':
      return 'public-token-live-6e4f486f-dd88-4bf7-b900-6a98ce048cf8';
    case 'staging':
      return 'public-token-live-6e4f486f-dd88-4bf7-b900-6a98ce048cf8';
    default:
      return 'public-token-test-fe2c222a-cb8f-4f75-ad36-3a35d9a669cb';
  }
};

export const getStytchLoginTemplateId = (env: string): string => {
  switch (env) {
    case 'production':
      return 'email-template-live-ff08757d-98aa-4709-9fb3-9d7a9f819b69';
    case 'staging':
      return 'email-template-live-ff08757d-98aa-4709-9fb3-9d7a9f819b69';
    default:
      return 'email-template-test-f2d50465-63b4-4dd4-9528-790f1c79afbe';
  }
};

export const getStytchSignupTemplateId = (env: string): string => {
  switch (env) {
    case 'production':
      return 'email-template-live-35250bfa-e80d-4914-9cf6-93c69d4292df';
    case 'staging':
      return 'email-template-live-35250bfa-e80d-4914-9cf6-93c69d4292df';
    default:
      return 'email-template-test-9c9c4f0a-ce0d-4ba3-8c00-f96c385d4f46';
  }
};
