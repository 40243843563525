import { State } from '../store';

export type SelectedWalletPayload = string;

export type SelectedWalletAction = {
  type: 'SET_SELECTED_WALLET';
  payload: SelectedWalletPayload;
};

export const selectedWalletReducer = (
  state: State,
  payload: SelectedWalletPayload,
): State => ({
  ...state,
  selectedWallet: payload,
});
