import { State } from '../store';
import { TXHistoryType } from '../types';

export type RecentTxsPayload = TXHistoryType[];

export type RecentTxsAction = {
  type: 'SET_RECENT_TXS';
  payload: RecentTxsPayload;
};

export const recentTxsReducer = (
  state: State,
  payload: RecentTxsPayload,
): State => ({
  ...state,
  recentTxs: payload,
});
