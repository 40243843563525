export const sortByName = (
  a: { name: string },
  b: { name: string },
): number => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
  return 0;
};
export const sortByCoin = (
  a: { coin: string },
  b: { coin: string },
): number => {
  // put AVAX last in list: bch, btc, eth, avax
  if (a.coin === 'AVAX' && b.coin !== 'AVAX') return -1;
  if (b.coin === 'AVAX') return 1;

  if (a.coin.toLowerCase() < b.coin.toLowerCase()) return 1;
  if (a.coin.toLowerCase() > b.coin.toLowerCase()) return -1;
  return 0;
};

export const sortByTime = (
  a: { timestamp: number },
  b: { timestamp: number },
): number => {
  if (a.timestamp < b.timestamp) return 1;
  if (a.timestamp > b.timestamp) return -1;
  return 0;
};

export const sortByTxType = (
  a: { txType: string },
  b: { txType: string },
): number => {
  if (
    a.txType === 'TX_HISTORY_TYPE_RECEIVED' &&
    b.txType === 'TX_HISTORY_TYPE_SENT'
  )
    return -1;
  if (
    a.txType === 'TX_HISTORY_TYPE_SENT' &&
    b.txType === 'TX_HISTORY_TYPE_RECEIVED'
  )
    return 1;
  return 0;
};

// for device mgt
export const sortByModel = (
  a: { model: string },
  b: { model: string },
): number => {
  if (!a.model) return 1;
  if (!b.model) return -1;
  if (a.model.toLowerCase() < b.model?.toLowerCase()) return -1;
  if (a.model.toLowerCase() > b.model?.toLowerCase()) return 1;
  return 0;
};
