import { State } from '../store';
import { TLinkedDevice, TWalletsDisabled } from '../types';

export type LinkedDevicesPayload = TLinkedDevice[];
export type WalletsDisabledPayload = TWalletsDisabled;

export type LinkedDevicesAction = {
  type: 'SET_LINKED_DEVICES';
  payload: LinkedDevicesPayload;
};

export type WalletsDisabledAction = {
  type: 'SET_WALLETS_DISABLED';
  payload: WalletsDisabledPayload;
};

export const linkedDevicesReducer = (
  state: State,
  payload: LinkedDevicesPayload,
): State => {
  return { ...state, linkedDevices: payload };
};

export const walletsDisabledReducer = (
  state: State,
  payload: WalletsDisabledPayload,
): State => {
  return { ...state, walletsDisabled: payload };
};
