import { State } from '../store';

export type TMarketsFavoritesPayload = string;

export type TMarketsFavorites = TMarketsFavoritesPayload[];

export type TMarketsFavoritesAction = {
  type: 'TOGGLE_MARKETS_FAVORITES';
  payload: TMarketsFavoritesPayload;
};

export const toggleMarketsFavoritesReducer = (
  state: State,
  payload: TMarketsFavoritesPayload,
): State => {
  const index = state.marketsFavorites.indexOf(payload);

  return index === -1
    ? {
      ...state,
      marketsFavorites: [...state.marketsFavorites, payload],
    }
    : {
      ...state,
      marketsFavorites: state.marketsFavorites.filter(coin => coin !== payload),
    };
};
