import React, { Fragment } from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
  apiKey: '42de908efe26a29097e3c5ad718e3dfc',
  plugins: [new BugsnagPluginReact()],
  releaseStage:
    process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development',
});

const BugsnagReactPlugin = Bugsnag.getPlugin('react');

const ErrorBoundary = BugsnagReactPlugin
  ? BugsnagReactPlugin.createErrorBoundary(React)
  : Fragment;

export default ErrorBoundary;
