import { ThemeProvider } from 'styled-components';
import React, { FunctionComponent } from 'react';
import { useLocation } from '@reach/router';
import themes, {
  GlobalStyle,
} from '@bitcoin-portal/neko-web-components/dist/themes';
import OsanoClose from '@bitcoin-portal/neko-web-components/dist/OsanoClose';

import Intl from '../../i18n';
import { useTrackedState } from '../../context';

type Props = {
  netlify?: Record<string, unknown>;
  localeDir?: string;
  locale?: string;
};

const Layout: FunctionComponent<Props> = props => {
  const { children, netlify, localeDir, locale } = props;
  const location = useLocation();
  const { pathname } = location || { pathname: localeDir };
  const { theme, locale: trackedLocale } = useTrackedState();
  const providerTheme = {...themes[theme], type: theme};

  return (
    <>
      <Intl
        locale={locale || trackedLocale}
        localeDir={netlify && localeDir ? localeDir : pathname}
        netlify={netlify}
      >
        <ThemeProvider theme={providerTheme}>
          <>
            <GlobalStyle />
            <OsanoClose />

            {children}
          </>
        </ThemeProvider>
      </Intl>
    </>
  );
};

export default Layout;
