import flattenMessages from '../../helpers/flattenMessages';
import en from './en.json';
import es from './es.json';
import ru from './ru.json';
import zh from './zh.json';

export default {
  en: flattenMessages(en, 'download'),
  es: flattenMessages(es, 'download'),
  ru: flattenMessages(ru, 'download'),
  zh: flattenMessages(zh, 'download'),
};
