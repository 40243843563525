import { ChartPeriodAction, chartPeriodReducer } from './reducers/chartPeriod';
import { LiveChartAction, liveChartReducer } from './reducers/chartPoint';
import { CoinInfoAction, coinInfoReducer } from './reducers/coinInfo';
import {
  ExchangeRatesAction,
  exchangeRatesReducer,
} from './reducers/exchangeRates';
import {
  FiatCurrencyAction,
  fiatCurrencyReducer,
} from './reducers/fiatCurrency';
import { GeoLocationAction, geoLocationReducer } from './reducers/geolocation';
import { LocaleAction, localeReducer } from './reducers/locale';
import { LogoutAction, logoutReducer } from './reducers/logout';
import { MarketChartsAction, marketChartsReducer } from './reducers/markets';
import {
  TMarketsFavoritesAction,
  toggleMarketsFavoritesReducer,
} from './reducers/marketsFavorites';
import { PortfolioAction, portfolioReducer } from './reducers/portfolio';
import { recentTxsReducer, RecentTxsAction } from './reducers/recentTxs';
import {
  SelectedWalletAction,
  selectedWalletReducer,
} from './reducers/selectedWallet';
import {
  setThemeReducer,
  ThemeAction,
  ToggleThemeAction,
  toggleThemeReducer,
} from './reducers/theme';
import { UserDetailsAction, userDetailsReducer } from './reducers/userDetails';
import {
  GeneralNotificationsAction,
  generalNotificationsReducer,
  UpdateGeneralNotificationsAction,
  updateGeneralNotificationsReducer,
  PriceNotificationsAction,
  priceNotificationsReducer,
  UpdatePriceNotificationsAction,
  updatePriceNotificationsReducer,
  EmailSubscriptionAction,
  emailSubscriptionReducer,
} from './reducers/userNotifications';
import { WalletsAction, walletsReducer } from './reducers/wallets';
import {
  SupportedAssetsAction,
  supportedAssetsReducer,
} from './reducers/supportedAssets';
import {
  LinkedDevicesAction,
  linkedDevicesReducer,
  WalletsDisabledAction,
  walletsDisabledReducer,
} from './reducers/device';

import { State } from './store';
import { InboxMessagesAction, inboxMessagesReducer } from './reducers/inbox';

export type Action =
  | SelectedWalletAction
  | WalletsAction
  | SupportedAssetsAction
  | FiatCurrencyAction
  | ExchangeRatesAction
  | GeoLocationAction
  | LocaleAction
  | ChartPeriodAction
  | LiveChartAction
  | LogoutAction
  | ToggleThemeAction
  | MarketChartsAction
  | PortfolioAction
  | CoinInfoAction
  | UserDetailsAction
  | GeneralNotificationsAction
  | PriceNotificationsAction
  | UpdateGeneralNotificationsAction
  | UpdatePriceNotificationsAction
  | EmailSubscriptionAction
  | ThemeAction
  | RecentTxsAction
  | InboxMessagesAction
  | TMarketsFavoritesAction
  | LinkedDevicesAction
  | WalletsDisabledAction;

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_WALLETS':
      return walletsReducer(state, action.payload);
    case 'SET_SELECTED_WALLET':
      return selectedWalletReducer(state, action.payload);
    case 'SET_SUPPORTED_ASSETS':
      return supportedAssetsReducer(state, action.payload);
    case 'SET_RECENT_TXS':
      return recentTxsReducer(state, action.payload);
    case 'SET_CHART_PERIOD':
      return chartPeriodReducer(state, action.payload);
    case 'SET_LOCALE':
      return localeReducer(state, action.payload);
    case 'SET_FIAT_CURRENCY':
      return fiatCurrencyReducer(state, action.payload);
    case 'SET_USER_DETAILS':
      return userDetailsReducer(state, action.payload);
    case 'SET_GEO_LOCATION':
      return geoLocationReducer(state, action.payload);
    case 'SET_NOTIFICATIONS_GENERAL':
      return generalNotificationsReducer(state, action.payload);
    case 'UPDATE_NOTIFICATIONS_GENERAL':
      return updateGeneralNotificationsReducer(state, action.payload);
    case 'SET_NOTIFICATIONS_PRICE':
      return priceNotificationsReducer(state, action.payload);
    case 'UPDATE_NOTIFICATIONS_PRICE':
      return updatePriceNotificationsReducer(state, action.payload);
    case 'SET_EMAIL_SUBSCRIPTION':
      return emailSubscriptionReducer(state, action.payload);
    case 'SET_PORTFOLIO':
      return portfolioReducer(state, action.payload);
    case 'SET_LIVE_CHART':
      return liveChartReducer(state, action.payload);
    case 'SET_EXCHANGE_RATES':
      return exchangeRatesReducer(state, action.payload);
    case 'SET_MARKET_CHARTS':
      return marketChartsReducer(state, action.payload);
    case 'TOGGLE_MARKETS_FAVORITES':
      return toggleMarketsFavoritesReducer(state, action.payload);
    case 'SET_COIN_INFO':
      return coinInfoReducer(state, action.payload);
    case 'TOGGLE_THEME':
      return toggleThemeReducer(state);
    case 'SET_THEME':
      return setThemeReducer(state, action.payload);
    case 'SET_INBOX_MESSAGES':
      return inboxMessagesReducer(state, action.payload);
    case 'SET_LINKED_DEVICES':
      return linkedDevicesReducer(state, action.payload);
    case 'SET_WALLETS_DISABLED':
      return walletsDisabledReducer(state, action.payload);
    case 'LOGOUT':
      return logoutReducer(state);
    default:
      return state;
  }
};
