import { EventController } from '@bitcoin-portal/neko-web-sdk';
import { getAssets } from '../../helpers/assets';
import { sortByName, sortByCoin } from '../../helpers/sorters';
import { State } from '../store';
import { WalletType } from '../types';
import { COINS } from '../constants';

export type WalletsPayload = WalletType[];

export type WalletsAction = {
  type: 'SET_WALLETS';
  payload: WalletsPayload;
};

export const walletsReducer = (
  state: State,
  payload: WalletsPayload,
): State => {
  if (
    !state.userDetails?.hasWallets &&
    state.wallets.length === 0 &&
    payload.length > 0
  ) {
    EventController.logEvent.walletLinked({
      wallets: payload.reduce((p: string[], c) => [...p, c.walletId], []),
    });
  }

  const wallets = payload
    .reduce((prev: WalletType[], curr) => {
      if (prev.find(i => i.walletId === curr.walletId)) return prev;
      return [...prev, curr];
    }, [])
    .sort(sortByName)
    .reverse()
    .sort(sortByCoin)
    .reverse();

  const assets = getAssets(wallets).map(i => {
    if (i.name === 'BCH_BLOCKCHAIN') return { ...i, name: 'Bitcoin Cash' };
    if (i.name === 'BTC_BLOCKCHAIN') return { ...i, name: 'Bitcoin' };
    return i;
  });

  const assetTickers = assets.map(a => a.ticker);
  const tickerSet = new Set([...COINS, ...assetTickers]);
  const cryptos = Array.from(tickerSet);

  const valueAssets = assets.filter(a => a.balance !== 0);

  if (!state.selectedWallet) {
    const visibleWallet = wallets.find(
      w => !state.walletsDisabled?.[w.walletId],
    );
    // set selectedWallet to first wallet that is not hidden
    return {
      ...state,
      wallets,
      assets: valueAssets,
      cryptos,
      selectedWallet: visibleWallet?.walletId,
    };
  }

  return { ...state, wallets, assets: valueAssets, cryptos };
};
