import { TMarketsData } from '@bitcoin-portal/neko-web-sdk';
import { State } from '../store';

export type MarketChartsPayload = TMarketsData;

export type MarketChartsAction = {
  type: 'SET_MARKET_CHARTS';
  payload?: MarketChartsPayload;
};

export const marketChartsReducer = (
  state: State,
  payload?: MarketChartsPayload,
): State => ({
  ...state,
  marketCharts: payload,
});
