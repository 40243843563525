import { State } from '../store';

export type LiveChartPayload = { time: number; open: number }[];

export type LiveChartAction = {
  type: 'SET_LIVE_CHART';
  payload: LiveChartPayload;
};

export const liveChartReducer = (
  state: State,
  payload: LiveChartPayload,
): State => {
  return { ...state, liveChart: payload };
};
