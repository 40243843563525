/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';
import { EventController } from '@bitcoin-portal/neko-web-sdk';
import { WebB } from '@bitcoin-portal/webb';
import { Provider } from './src/context';
import CustomLayout from './wrapPageElement';
import ErrorBoundary from './src/components/ErrorBoundary';
import { activeEnv } from './src/context/constants';

export const wrapRootElement = ({ element }) => (
  <ErrorBoundary>
    <Provider>{element}</Provider>
  </ErrorBoundary>
);

export const wrapPageElement = CustomLayout;

export const onInitialClientRender = () => {
  window.webB = new WebB();

  const AMPLITUDE_API_KEY =
    activeEnv === 'production'
      ? '498ce6d5e47c1f87c8c0d2bd1078c4d1'
      : 'fa7b1cc04ab3b1dd731237ca07c2ab94';

  EventController.init(AMPLITUDE_API_KEY);
};

export const onRouteUpdate = props => {
  const { location } = props;
  const contentProps = { contentType: location.pathname, hasWallets: false };
  try {
    if (window.localStorage) {
      const globalState = window.localStorage.getItem('bitcoincom-wallet-web');
      const json = JSON.parse(globalState);
      contentProps.hasWallets = json?.userDetails?.hasWallets || false;
    }
  } catch (error) {
    console.log('Route update error', error);
  }
  EventController.logEvent.viewContent(contentProps);
};

export const onServiceWorkerUpdateReady = () => {
  if (!window?.location) return;

  window.location.reload();
};
