import { InboxMessage } from '@bitcoin-portal/neko-web-sdk';
import { State } from '../store';

export type InboxMessagesPayload = InboxMessage[];

export type InboxMessagesAction = {
  type: 'SET_INBOX_MESSAGES';
  payload: InboxMessagesPayload;
};

export const inboxMessagesReducer = (
  state: State,
  payload: InboxMessagesPayload,
): State => ({ ...state, inboxMessages: payload });
