import {
  WalletUtxos,
  WalletTXHistory,
  UserPortfolio,
} from '@bitcoin-portal/neko-web-sdk';
import { Wallet } from '../store';
import utxos from './utxos.json';
import transactions from './transactions.json';
import portfolio from './portfolio.json';
import wallets from './wallets.json';

export const sampleWallets = wallets as Wallet[];

export const sampleAllAssets = utxos as WalletUtxos[];

export const sampleTransactions = transactions as WalletTXHistory[];

export const samplePortfolio = portfolio as UserPortfolio[];
