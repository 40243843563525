import { State } from '../store';

export type ChartPeriodPayload = string;

export type ChartPeriodAction = { type: 'SET_CHART_PERIOD'; payload: string };

export const chartPeriodReducer = (
  state: State,
  payload: ChartPeriodPayload,
): State => ({ ...state, chartPeriod: payload });
