import { initStytch, StytchProvider } from '@stytch/stytch-react';
import { PageRendererProps } from 'gatsby';
import React, { FunctionComponent, ReactNode } from 'react';

// TODO: Remove this once dataloader gets fixed https://github.com/graphql/dataloader/issues/249
import 'setimmediate';
import Layout from './src/components/Layout';
import { activeEnv } from './src/context/constants';
import { getStytchPublicToken } from './src/helpers/stytch';

interface Props {
  element: ReactNode;
  props: PageRendererProps;
}

const stytchPublicToken = initStytch(getStytchPublicToken(activeEnv));

const wrapPageElement: FunctionComponent<Props> = ({ element, props }) => {
  return (
    <StytchProvider stytch={stytchPublicToken}>
      <Layout {...props}>{element}</Layout>
    </StytchProvider>
  );
};

export default wrapPageElement;
