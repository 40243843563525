import { sortByCoin } from '../../helpers/sorters';
import { State } from '../store';

// general notifications
export type GeneralNotificationPayload = {
  enabled: boolean;
  notificationId: string;
};

export type GeneralNotificationsAction = {
  type: 'SET_NOTIFICATIONS_GENERAL';
  payload?: GeneralNotificationPayload[];
};

export const generalNotificationsReducer = (
  state: State,
  payload?: GeneralNotificationPayload[],
): State => ({ ...state, generalNotifications: payload });

export type UpdateGeneralNotificationsAction = {
  type: 'UPDATE_NOTIFICATIONS_GENERAL';
  payload: GeneralNotificationPayload;
};

export const updateGeneralNotificationsReducer = (
  state: State,
  payload: GeneralNotificationPayload,
): State => {
  const { generalNotifications } = state;
  const existingNotifications =
    generalNotifications?.filter(
      general => general.notificationId !== payload.notificationId,
    ) || [];

  return {
    ...state,
    generalNotifications: [...existingNotifications, payload],
  };
};

// price notifications
export type PriceNotificationPayload = {
  coin: string;
  enabled: boolean;
  intervalInMinutes: number;
};

export type PriceNotificationsAction = {
  type: 'SET_NOTIFICATIONS_PRICE';
  payload: PriceNotificationPayload[];
};

export const priceNotificationsReducer = (
  state: State,
  payload?: PriceNotificationPayload[],
): State => ({ ...state, priceNotifications: payload?.sort(sortByCoin) });

export type UpdatePriceNotificationsAction = {
  type: 'UPDATE_NOTIFICATIONS_PRICE';
  payload: PriceNotificationPayload;
};

export const updatePriceNotificationsReducer = (
  state: State,
  payload: PriceNotificationPayload,
): State => {
  const { priceNotifications } = state;
  const existingNotifications =
    priceNotifications?.filter(price => price.coin !== payload.coin) || [];

  return {
    ...state,
    priceNotifications: [...existingNotifications, payload].sort(sortByCoin),
  };
};

// email subscription for customer.io
export type Segment = 'news' | 'priceAlerts' | 'wallet';

export type EmailSubscriptionPayload = Record<Segment, boolean>;

export type EmailSubscriptionAction = {
  type: 'SET_EMAIL_SUBSCRIPTION';
  payload: EmailSubscriptionPayload;
};

export const emailSubscriptionReducer = (
  state: State,
  payload: EmailSubscriptionPayload,
): State => ({ ...state, subscribedToEmails: { ...state.subscribedToEmails, ...payload } });
