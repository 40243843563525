import { samplePortfolio } from '../sample';
import { State } from '../store';

export type PortfolioPeriod = {
  period: string;
  points: { timestamp: string; asset: string; price: number }[];
};

export type PortfolioPayload = PortfolioPeriod[];

export type PortfolioAction = {
  type: 'SET_PORTFOLIO';
  payload: PortfolioPayload;
};

export const portfolioReducer = (
  state: State,
  payload: PortfolioPayload,
): State => {
  if (!state.userDetails?.hasWallets) return { ...state, portfolio: samplePortfolio };
  return { ...state, portfolio: payload };
};
