import { AssetType, WalletType } from '../context/types';

export const combineAssets = (assets: AssetType[]): AssetType[] => {
  return assets.reduce((p: AssetType[], c): AssetType[] => {
    const dup = p.find(i => i.id === c.id);
    if (!dup) {
      return [...p, c];
    }

    const filtered = p.filter(i => i.id !== dup.id);

    const newItem = {
      ...dup,
      balance: Number.parseFloat(
        (c.balance + dup.balance).toFixed(dup.decimals),
      ),
    };

    return [...filtered, newItem];
  }, []);
};

export const getAssets = (wallets: WalletType[]): AssetType[] => {
  const reduced = wallets
    .filter(w => !w.isHidden)
    .reduce((prev: AssetType[], curr): AssetType[] => {
      return combineAssets([...prev, ...curr.assets.map(asset =>
        ({ ...asset, blockchain: curr.coin })
      )]);
    }, []);

  return reduced;
};
