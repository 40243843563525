import { State } from '../store';
import { ExchangeRatesType } from '../types';

export type ExchangeRatesAction = {
  type: 'SET_EXCHANGE_RATES';
  payload: ExchangeRatesType;
};

export const exchangeRatesReducer = (
  state: State,
  payload: ExchangeRatesType,
): State => ({
  ...state,
  exchangeRates: payload,
});
